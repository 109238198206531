import React  from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import 'bootstrap/dist/css/b?ootstrap.min.css';


// import Header from './components/Header'




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <App />


);

